import React, { useEffect, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import _, { isEmpty, map, without } from 'lodash';
import { MdAttachment, MdPermMedia } from 'react-icons/md';
import styles from '../formColumns/forms.module.scss';
import { dropZoneimageUploader } from '../../utils/imageUploadHelper';
import uploadStyling from './imageUpload.module.scss';
import { FaTimes as CloseIcon } from 'react-icons/fa';
import { Spinner } from '../loader/loader';
import { views } from '../../constants/views';
import { dropZonefileUploader } from '../../utils/fileUploadHelper';
import { set } from 'lodash';
import { handleJobData } from '../../redux/common/commonSlice';
import { useDispatch } from 'react-redux';
export { MdAttachment as AttachmentIcon } from 'react-icons/md';

export const ImageUpload = (props) => {
  const {
    input,
    label,
    meta,
    multiple,
    description,
    className,
    logoData,
    changeFunc,
    onAdd,
    onRemove,
    children,
    form,
    isEdit,
    setState,
    state,
    currentView,
    backgroundColor,
    from,
    ...rest
  } = props;

  const error = meta && meta.error;
  const id = `upload-${label}`;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      try {
        setLoading(true);

        if (currentView === views.talentNotes) {
          const response = await dropZonefileUploader(acceptedFiles[0]);
          setState((prevState) => ({
            ...prevState,
            file: response,
          }));
          setLoading(false);
          return;
        }
        const promises = [...acceptedFiles].map((file) => dropZoneimageUploader(file));
        const response = await Promise.all(promises);

        let localImages = _.cloneDeep(state)?.images;
        setState((prevState) => ({
          ...prevState,
          images: [...localImages, ...response],
        }));
        setLoading(false);
      } catch (err) {
        setLoading(false);
      } finally {
        setUpdate(true);
      }

      // Do something with the files
    },
    [state?.images, state?.file],
  );

  useEffect(() => {
    if (state?.images?.length) {
      location.pathname.includes('job') && dispatch(handleJobData({ state: state }));
    }
  }, [state?.images]);
  const handleCoverImages = (index) => {
    let images = _.cloneDeep(state)?.images;

    let coverImgIndex = images.findIndex((img) => img.is_cover);
    if (coverImgIndex >= 0) {
      images[coverImgIndex].is_cover = 0;
    }
    images[index].is_cover = 1;
    setState((prevState) => ({ ...prevState, images: images }));
    setUpdate(true);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleRemove = (index) => {
    let { images } = _.cloneDeep(state);

    images.splice(index, 1);
    setState((prevState) => ({ ...prevState, images: images }));
    setUpdate(true);
  };

  useEffect(() => {
    if (!update) return;
    try {
      if (isEdit == true) {
        form.setValue('client_images', [...state.images]);
        changeFunc({ body: form.getValues() });
      }
    } finally {
      // setUpdate(false);
    }

    // setUpdate(false);
  }, [update]);

  const [imageBG, setImageBG] = useState(false);

  useEffect(() => {
    let images = _.cloneDeep(state)?.images;
    if (images?.length > 1) {
      let cover = state?.images?.find((obj) => {
        return obj.is_cover == 1;
      });
      setImageBG(cover?.image);
    } else if (images?.length == 1) {
      setImageBG(images[0]?.image);
    }
  }, [state?.images]);

  return (
    <>
      <span
        className={classNames(
          'form-elements',
          styles.inputWrapper,
          styles.imageUploadWrapper,
          className,
        )}
      >
        <span className={classNames('form-elements-wrapper', styles.inputInnerWrapper)}>
          {label && (
            <label htmlFor={id && id} className={classNames('element-label pt-3', styles.label)}>
              {label}
              {description && (
                <p className={classNames('element-description', styles.textSmall, 'mb-3')}>
                  {description}
                </p>
              )}
            </label>
          )}
          <span className={'form-element'} style={{ backgroundImage: `url("${imageBG}")` }}>
            {currentView !== views.talentNotes ? (
              <div className={styles.fileInputWrapper}>
                <div
                  className={styles.dropInnerWrapper}
                  style={{ background: backgroundColor }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className={styles.dropzonePlaceholder}>
                    <MdPermMedia size={25} />
                    <p>Drag and drop photos here to upload or...</p>
                  </div>

                  {children}
                </div>
                {error && (
                  <span className={classNames(styles.fieldError, 'form-error-element')}>
                    {error}
                  </span>
                )}
              </div>
            ) : from ? (
              <div className={classNames('form-file', styles.fileUploadWrapper)}>
                <div
                  activeClassName={classNames(styles.dropInnerWrapperActive)}
                  className={uploadStyling.dropInnerAttachement}
                  {...getRootProps()}
                >
                  {loading ? <Spinner height={20} width={20} /> : <input {...getInputProps()} />}
                  <div
                    className={classNames(
                      styles.fileInput,
                      'mb-0',
                      'd-flex',
                      'align-items-center',
                      'justify-content-between',
                      'w-100',
                    )}
                  >
                    <div className="d-flex">
                      {!loading && <MdAttachment size={20} className="me-1" />}
                      <span className={uploadStyling.attachmentName}>
                        {state.file?.name || (!loading && 'Upload Attachment')}
                      </span>
                    </div>

                    {state?.file && (
                      <CloseIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          setState((prevState) => ({
                            ...prevState,
                            file: null,
                          }));
                          setUpdate(true);
                        }}
                      />
                    )}
                  </div>

                  {children}
                </div>
                {error && (
                  <span className={classNames(styles.fieldError, 'form-error-element')}>
                    {error}
                  </span>
                )}
              </div>
            ) : (
              <div className={classNames('form-file', styles.fileUploadWrapper)}>
                <div
                  activeClassName={classNames(styles.dropInnerWrapperActive)}
                  className={uploadStyling.dropInnerAttachement}
                  {...getRootProps()}
                >
                  {loading ? <Spinner height={20} width={20} /> : <input {...getInputProps()} />}
                  <div
                    className={classNames(
                      styles.fileInput,
                      'mb-0',
                      'd-flex',
                      'align-items-center',
                      'justify-content-between',
                      'w-100',
                    )}
                  >
                    <div className="d-flex">
                      {!loading && <MdAttachment size={20} className="me-1" />}
                      <span className={uploadStyling.attachmentName}>
                        {state.file?.name || (!loading && 'Upload Attachment')}
                      </span>
                    </div>

                    {state?.file && (
                      <CloseIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          setState((prevState) => ({
                            ...prevState,
                            file: null,
                          }));
                          setUpdate(true);
                        }}
                      />
                    )}
                  </div>

                  {children}
                </div>
                {error && (
                  <span className={classNames(styles.fieldError, 'form-error-element')}>
                    {error}
                  </span>
                )}
              </div>
            )}
          </span>
        </span>
      </span>

      {currentView !== views.talentNotes && !isEmpty(state?.images) && (
        <div className={classNames(uploadStyling.imgUploadPreviewContainer)}>
          {loading ? (
            <div className=" w-100 d-flex justify-content-center">
              <Spinner height={100} width={100} />
            </div>
          ) : (
            map(state?.images, (image, index) => (
              <div
                key={`file-${index}`}
                className={classNames(styles.fileInputList, {
                  [styles.isDefaultFile]: image.is_cover,
                })}
              >
                <div
                  className={classNames(styles.fileDetails, 'h-100', {
                    [styles.uploadError]: image.uploadError,
                  })}
                >
                  <>
                    <div
                      className={classNames(uploadStyling.imgUploadPreview, {
                        [uploadStyling.imgUploadPreviewCover]: image.is_cover,
                      })}
                    >
                      <img
                        className="img-fluid w-100"
                        alt="Preview"
                        src={image.url || image.secure_url || image.image}
                        data-tip={image.name}
                      />

                      {!image.is_cover && (
                        <a
                          onClick={() => handleCoverImages(index)}
                          className={uploadStyling.setAsCover}
                        >
                          {/* <CheckIcon size={16} /> */}
                          <span>Set as cover</span>
                        </a>
                      )}
                      <a
                        className={uploadStyling.imageUploadDelete}
                        onClick={() => {
                          handleRemove(index);
                        }}
                      >
                        <CloseIcon size={20} />
                      </a>
                    </div>
                  </>
                  {/* ))} */}
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </>
  );
};
